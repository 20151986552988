import React from "react"
import ProductNotFound from "./productNotFound"

export default function Product({ product }) {
  if (!product) return (
    <ProductNotFound />
  );

  if (product.node) product = product.node;

  return (
    <div className="product full-page-module">
      <div className="product-name">{product.name}</div>
      <div className="product-cta">
        <a href={product.affiliateUrl || product.scrapeUrl}>
          <span className="link-spanner"></span>
        </a>
        <div className="product-cta_text">
          Buy from {product.retailer}
        </div>
      </div>
    </div>
  );
};