import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Product from "../components/product"
import ProductNotFound from "../components/productNotFound"

const stop = ( props ) => {
  const product = props.data.mongodbDevelopProducts;

  return (
    <Layout blog={true}>
    {product ? (
        <Product product={product} />
        ) : (
        <ProductNotFound />
      )
    }
    </Layout>
  )
}


export const pageQuery = graphql`
  query($id: String!) {
    mongodbDevelopProducts(mongodb_id: { eq: $id }) {
      name
      scrapeUrl
      affiliateUrl
      retailer
    }
  }
`

export default stop;
